import {ProductSearchProperties, ProductType,} from '../../../@types/codegen/graphql';

export const generateProductTitle = (
  type: Pick<ProductType, 'title' | 'manufacturer'>,
  properties: Pick<ProductSearchProperties, 'key' | 'value' | 'isVariant' | 'descriptor'>[]
): string => {
  const variants: string[] | undefined = properties
    .filter(property => true === property.isVariant)
    .map<string>(property => {
      const enums = property.descriptor?.enums ?? [];
      const propertyValue = property?.value.toString();
      const propertyKey = property?.key;
      let title = propertyKey ?? '';

      if (propertyValue) {
        title = enums.find(penum => -1 !== propertyValue.indexOf(penum.key))?.title ?? propertyKey;
      }

      return title;
    });

  return `${type?.manufacturer?.title} ${type?.title} ${0 < variants.length ? ` - ${variants.join(' | ')}` : ''}`;
};
