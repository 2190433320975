import React, { FC, ReactElement } from 'react';
import parse, { HTMLReactParserOptions, Element } from 'html-react-parser';
import { LinkType } from '../StrapiLink/components/link-type';

type StrapiTextConverterProps = {
  text?: string | null;
}

export const LinkInTextConverter: FC<StrapiTextConverterProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <div className={'content-wrapper'}>
      {parse(text, options)}
    </div>
  );
};

const options: HTMLReactParserOptions = {
  transform: (reactNode, domNode) => {
    // replace a-tag with LinkType
    if (domNode instanceof Element && domNode.name === 'a') {
      let url = domNode.attribs.href ?? '';
      let pageName = 'internal';

      if (url.startsWith('http') || url.startsWith('www')) {
        pageName = 'external';
      } else if (url.startsWith('tel') || url.startsWith('mailto')) {
        pageName = 'other';
      }

      // Replace the root category url with the original url
      if (url.endsWith('/search')) {
        url = url.replace('/search', '');
      }

      const content = (reactNode as ReactElement)?.props?.children ?? '';

      return <LinkType url={url} pageName={pageName} title={''}>
        <>{content}</>
      </LinkType>;
    }

    return <>{reactNode}</>;
  },
};
