import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import styled from '@emotion/styled';
import {
  spacingTopBottom,
} from '@digando/react-component-library';
import FilterRentalStationByCountry from './components/filter-rental-station-by-country';
import { ComponentContentRentalStations, StrapiImageFragment } from '../../../@types/codegen/graphql';
import { useRentalStations } from '../../../@types/codegen/page';

type RentalStationsProps = ComponentContentRentalStations & { sliceIndex: string; };

const RentalStations: FC<RentalStationsProps> = ({ image, tenantKey }) => {
  if (undefined == tenantKey) {
    return null;
  }

  const { data } = useRentalStations(() => {
    return {
      variables: {
        params: {
          tenantKey
        }
      },
      fetchPolicy: 'cache-only',
    };
  });

  const rentalStations = data?.rentalStations?.data ?? [];

  if (rentalStations.length === 0) {
    return null;
  }

  const countries = rentalStations.map(rentalStation => rentalStation.country)
    .filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.iso2 === value.iso2
      )),
    );

  return (
    <RentalStationsSection>
      {countries?.map((country) => {
        return (
          <RentalStationsCountryContainer key={country.iso2}>
            <FilterRentalStationByCountry
              tenantKey={tenantKey}
              image={image?.data?.attributes as StrapiImageFragment}
              country={country}
              rentalStations={rentalStations}
            />
          </RentalStationsCountryContainer>);
      }) ?? null}
    </RentalStationsSection>
  );
};

const RentalStationsSection = styled(ContentContainerMax)`
  ${spacingTopBottom(2)}
`;

const RentalStationsCountryContainer = styled.div`
  grid-column: auto / span 12;
`;

export default RentalStations;
