// @todo: Add image helper

import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import { FontPrimarySemiBold, FontSecondaryRegular } from '../../../styles/fonts';
import { FactList } from '../FactList/FactList';
import { ProductTitle } from './ProductTitle';
import { spacingLeftRight, spacingTop, spacingTopBottom } from '../../../helper/spacing';
import { mediaQueries } from '../../../styles/media-queries';

export interface IProductCard {
  tagName?: string;
  productTitle: string;
  productPrice: ReactElement;
  partnerLogo: ReactElement;
  productImg: ReactElement;
  productInfoArray: { id: number; name: string; value: string }[];
  additionalData?: ReactElement;
  isFactListExpanded?: boolean;
}

export interface IExpandedContainer {
  isExpanded: boolean | undefined;
}

export const ProductCard: FC<IProductCard> = (props): ReactElement => {
  const {
    tagName,
    productTitle,
    productPrice,
    productImg,
    partnerLogo,
    productInfoArray,
    additionalData,
    isFactListExpanded,
  } = props;

  return (
    <CardWrapper>
      <ProductImgContainer>
        {productImg}
        {tagName && <Tag> {tagName} </Tag>}
      </ProductImgContainer>
      <ProductTitleContainer>
        <ProductTitle productTitle={productTitle} />
        <ProductSubTitleContainer>
          {productPrice}
          {partnerLogo}
        </ProductSubTitleContainer>
      </ProductTitleContainer>
      <FactListContainer isExpanded={isFactListExpanded}>
        <FactList facts={productInfoArray} />
      </FactListContainer>
      {additionalData && (
        <DetailsContainer>
          {additionalData}
        </DetailsContainer>
      )}
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  --spacing-steps: 4px;

  width: 100%;
  min-width: 280px;
  height: auto;
  background-color: var(--white);
  border: 1px solid var(--border-light-color);
  font-style: var(--font-primary-regular-style);

  @media ${mediaQueries.tablet} {
    max-width: 520px;
  }
`;

const ProductImgContainer = styled.div`
  display: flex;
  position: relative;
`;

const Tag = styled.div`
  background-color: var(--secondary-color);
  border-radius: 3px 0 0 3px;
  top: 10%;
  height: 29px;
  ${spacingTopBottom(0.5)};
  ${spacingLeftRight(1)};
  position: absolute;
  right: 0;
  font-size: var(--font-size-text-small);
  ${FontPrimarySemiBold};
  color: var(--white);
`;

const ProductTitleContainer = styled.div`
  background-color: var(--background-light);
  display: flex;
  position: relative;
  flex-direction: column;
  ${FontSecondaryRegular};
  ${spacingTopBottom(3)};
  ${spacingLeftRight(2)};
`;

const ProductSubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${spacingTop(2)};

  @media ${mediaQueries.tablet} {
    ${spacingTop(1)};
  }
`;

const FactListContainer = styled.div<IExpandedContainer>`
  ${spacingLeftRight(2)};

  @media ${mediaQueries.tablet} {
    ${spacingLeftRight(2)};
  }
`;

const DetailsContainer = styled.div`
  border-top: 1px solid var(--border-light-color);
  display: flex;
  position: relative;
  flex-direction: column;
  ${spacingTopBottom(3)};
  ${spacingLeftRight(4)};
`;
