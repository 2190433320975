import { FC, useEffect, useState } from 'react';
import { spacingTopBottom } from '@digando/react-component-library';
import styled from '@emotion/styled';

import { ComponentContentRecommendedCategories } from '../../../@types/codegen/graphql';
import { useRecommendedProductsSlice } from '../../../@types/codegen/page';
import { ContentContainerMax } from '../../../layout/container';
import { ZoneTitle } from '../../zone-title';
import RecommendedProductsCarousel from '../../../components/recommended-products/carousel-recommended-products';
import {
  RecommendedProductsItemFragment,
} from '../../../@types/codegen/graphql';
import FilterMenu from '../../../components/recommended-products/filter-menu';
import { useSession } from 'next-auth/react';

type RecommendedProductsProps = ComponentContentRecommendedCategories & { sliceIndex: string; };

const RecommendedProducts: FC<RecommendedProductsProps> = ({ categories, recommendedCategoryTitle, tenantKey }) => {
  const { status } = useSession();
  const [activeCategory, setActiveCategory] = useState<string | null>(categories?.[0]?.id ?? null);
  const category = categories.find(category => (category?.id ?? null) === activeCategory);
  const productTypeUriComponents = category?.products?.map(product => product?.uriComponent)
    .filter(uriComponent => true === !!uriComponent) as string[] ?? [];

  const { data, refetch } = useRecommendedProductsSlice(() => {
    return {
      variables: {
        recommendedProductParams: {
          tenantKeys: tenantKey ? [tenantKey] : [],
          productTypeUriComponents: productTypeUriComponents,
          countryCode: null,
        },
      },
      fetchPolicy: 'cache-only',
      nextFetchPolicy: 'cache-first',
    };
  });

  const [allProducts, setAllProducts] = useState<RecommendedProductsItemFragment[]>(data?.recommendedProducts?.products ?? []);

  useEffect(() => {
    refetch({
      recommendedProductParams: {
        tenantKeys: tenantKey ? [tenantKey] : [],
        productTypeUriComponents: productTypeUriComponents,
        countryCode: null,
      },
    });
  }, [status, activeCategory]);

  useEffect(() => {
    const products = data?.recommendedProducts?.products ?? [];

    if (data) {
      setAllProducts(products);
    }
  }, [JSON.stringify(data?.recommendedProducts?.products ?? '[]')]);

  const onFilterChange = (activeFilter: string): void => {
    setActiveCategory(activeFilter);
  };

  /**
   * If there are no products, we don't want to render anything.
   */
  if (0 === allProducts.length) {
    console.info('No products found for recommended products');
  }

  return (
    <Wrapper>
      <ContentContainerMax>
        {recommendedCategoryTitle && <ZoneTitle title={recommendedCategoryTitle} variant={'h2'} />}
      </ContentContainerMax>

      <FilterMenu onFilterChange={onFilterChange} items={categories} />

      <RecommendedProductsCarousel products={allProducts} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${spacingTopBottom(4)};
`;

export default RecommendedProducts;
