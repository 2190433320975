import React, { FC } from 'react';
import { spacingTopBottom } from '@digando/react-component-library';
import styled from '@emotion/styled';

import { ContentContainerMax } from '../../../layout/container';
import { CategoryOverviewSection } from '../../../components/category-overview-section/category-overview-section';
import { ComponentContentCategoryOverview } from '../../../@types/codegen/graphql';
import { ZoneTitle } from '../../zone-title';

type CategoryOverviewProps = ComponentContentCategoryOverview & { sliceIndex: string; };

const CategoryOverview: FC<CategoryOverviewProps> = ({ heading, description }) => (
  <Container>
    {heading?.title && (
      <Fullwidth>
        <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} />
      </Fullwidth>
    )}

    {description && (
      <Fullwidth>
        <p>{description}</p>
      </Fullwidth>
    )}
    <CategoryOverviewSection />
  </Container>
);

const Container = styled.div`
  ${spacingTopBottom(10)};
  width: 100%;
  background-color: var(--category-overview-container-background-color);
`;

const Fullwidth = styled(ContentContainerMax)`
  grid-template-columns: repeat(1, 1fr);
`;

export default CategoryOverview;
