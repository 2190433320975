import React, { FC } from 'react';
import { Card } from '../../../components/elements/Card/Card';
import { Hyperlink } from '../../../components/elements/Typography/Link/Hyperlink';
import InternalLinkImage from './components/link-image';
import { LinkType } from './components/link-type';
import { useTranslation } from 'next-i18next';
import { InternalLinkPageTypeFragment } from '../../../@types/codegen/graphql';

type StrapiLinkProps = {
  pageData?: InternalLinkPageTypeFragment | null;
  // This property will increase the used image-formats to be valid for the bigger container.
  isContainerMax?: boolean;
}

export const StrapiLink: FC<StrapiLinkProps> = ({ pageData, isContainerMax = false }) => {
  const [t] = useTranslation(['common']);

  if (!pageData || !pageData.slug) {
    return null;
  }

  let pageType = pageData.contentType;

  if (!pageType || '' === pageType) {
    pageType = 'external';
  }

  return (
    <LinkType
      url={pageData.slug ?? ''}
      pageName={pageType}
      title={pageData.readMoreLinkTitle ?? pageData.title ?? ''}
      tenantKey={pageData?.tenantKey ?? undefined}
    >
      <Card
        text={pageData.description ?? ''}
        title={pageData.title ?? ''}
        renderLink={
          <Hyperlink iconRight={'link-arrow'}>
            {pageData.readMoreLinkText || t('common:more-on-this')}
          </Hyperlink>
        }
        renderImage={
          <InternalLinkImage isContainerMax={isContainerMax} image={pageData.image?.attributes ?? null} />
        }
      />
    </LinkType>
  );
};
