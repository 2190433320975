import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { mediaQueries } from '../../../styles/media-queries';
import { FontPrimaryRegular, FontPrimarySemiBold } from '../../../styles/fonts';
import { spacingTopBottom } from '@digando/react-component-library';

export interface IFactListFact {
  name: string | ReactNode;
  value: string;
  id: number;
}

export interface IFactList {
  facts: IFactListFact[];
  border?: boolean;
  fill?: boolean;
}

export const FactList: FC<IFactList> = (props): React.ReactElement => {
  const { fill = true } = props;
  let { facts } = props;

  if (true === fill && 3 > facts.length) {
    facts = [...new Array(3)].map((item, index) => {
      if (facts[index]) {
        return facts[index];
      }

      return { id: index, name: (<>&nbsp;</>), value: '' };
    });
  }

  return (
    <List>
      {facts.map(fact => (
        <FactItem key={fact.id}>
          <FactLabel>{fact.name}</FactLabel>
          <FactValue>{fact.value}</FactValue>
        </FactItem>
      ))}
    </List>
  );
};

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: var(--black)
`;

const FactItem = styled.li`
  display: flex;
  ${spacingTopBottom(1)}
`;

const FactLabel = styled.div`
  ${FontPrimaryRegular};
  font-size: var(--font-size-text-small);
  line-height: 22px;

  @media ${mediaQueries.tablet} {
    font-size: var(--font-size-text);
  }
`;

const FactValue = styled.div`
  margin-left: auto;
  ${FontPrimarySemiBold};
  font-size: var(--font-size-text-small);
  line-height: 22px;
  text-align: right;
  word-break: break-word;

  @media ${mediaQueries.tablet} {
    font-size: var(--font-size-text);
  }
`;
