import React, { FC, useState } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import {
  spacingBottom,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import { mediaQueries } from '../../../styles/media-queries';
import { Faq } from '../../../components/elements/Faq/Faq';
import { ComponentContentFaq } from '../../../@types/codegen/graphql';
import { ZoneTitle } from '../../zone-title';
import { LinkInTextConverter } from '../StrapiLink/link-in-text-converter';

type FaqSectionProps = ComponentContentFaq & { sliceIndex: string; };

const FaqSection: FC<FaqSectionProps> = ({ heading, faqs }) => {
  const [openId, setOpenId] = useState<string | null>(null);

  if ((faqs?.data.length ?? 0) === 0) return null;

  return (
    <ContentContainerMax>
      <FaqSessionWrapper>
        {heading?.title && <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h3'} />}

        {faqs?.data.map((faq, index) => {
          if (!faq.attributes?.question || !faq.attributes?.answer) return null;

          return (
            <Faq
              key={faq.id}
              id={faq?.id ?? ''}
              isOpen={faq.id === openId}
              onClick={(id) => setOpenId(openId === id ? null : id)}
              title={faq.attributes.question}
              text={<LinkInTextConverter text={faq.attributes?.answer}/>
              }
            />
          );
        })}
      </FaqSessionWrapper>
    </ContentContainerMax>
  );
};

const FaqSessionWrapper = styled.div`
  ${spacingBottom(4)};
  grid-column-start: 1;
  grid-column-end: 16;

  @media ${mediaQueries.tablet} {
    ${spacingBottom(6)};
  }

  @media ${mediaQueries.desktop} {
    ${spacingBottom(8)};
  }
`;

export default FaqSection;
