import Link from 'next/link';
import React, { FC } from 'react';
import styles from './category-card-header.module.scss';
import classNames from 'classnames';
import { CategoryTreeFragment } from '../../@types/codegen/graphql';

type CategoryCardHeaderProps = {
  category: CategoryTreeFragment;
};



export const CategoryCardHeader: FC<CategoryCardHeaderProps> = ({ category }) => {
  return (
    <div className={classNames({
      [styles.categoryCardHeader]: true,
    })}>
      <div className={styles.titleWrapper}>
        {category?.image?.url && <div className={styles.categoryIcon} style={{ mask: `url(${category.image.url})`}} />}

        <Link
          key={category?.id}
          href={category?.relativeUrl ?? ''}
          passHref
          legacyBehavior>
          {category?.title}
        </Link>
      </div>
    </div>
  );
};
