import  { FC } from 'react';
import styled from '@emotion/styled';
import { spacingTopBottom } from '@digando/react-component-library';
import { TextWithImageProps } from '../index';
import { Hyperlink } from '../../../../components/elements/Typography/Link/Hyperlink';
import { LinkType } from '../../StrapiLink/components/link-type';

export const CtaLink: FC<TextWithImageProps> = (props) => {
  const { cta} = props;
  const { text, ctaLinkPage } = cta ?? {};

  if (!cta || !cta.ctaLinkPage) {
    return null;
  }

  const url = ctaLinkPage?.slug ?? '';
  const pageName = ctaLinkPage?.contentType ?? '';
  const linkTitle = ctaLinkPage?.title ?? '';

  return (
    <CTA>
      <LinkType url={url} pageName={pageName} title={linkTitle}>
        <span>
          <Hyperlink iconRight={'link-arrow'}>{text}</Hyperlink>
        </span>
      </LinkType>
    </CTA>
  );
};

const CTA = styled.div`
  ${spacingTopBottom(1)};

  @media print {

    img {
      page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-inside: avoid; /* or 'auto' */
    }
  }
`;

