import {
  EnumPropertyValue,
  PropertyType
} from '../@types/codegen/graphql';
import { IFormattedProperty } from './types/formatted-property.interface';

/**
 * Parse property information for fact list.
 */
export interface IBaseProperty {
  descriptor: {
    type?: string;
    unit?: string | null;
    enums?: Array<EnumPropertyValue> | null;
  };
  values: unknown;
}

// Sanitizes a values object, try to sanitize to min/max structure.
// Currently we have three different possibilities how a product property can provide values:
// - Plain integer
// - Array of integers (Two entries, provide min/max)
// - Object with min/max values
// @todo: Introduce a cleaner product properties structure to be used all over the frontend
const convertIntegerValues = (values: unknown): { min: number; max: number } => {
  let valueMin: number;
  let valueMax: number;

  if ('number' === typeof values) {
    valueMin = values as number;
    valueMax = values as number;
  } else if (Array.isArray(values)) {
    valueMin = values[0] as number;
    valueMax = values[1] as number;
  } else {
    const objValue = values as { min: number; max: number };

    valueMin = objValue?.min ?? 0;
    valueMax = objValue?.max ?? 0;
  }

  return {
    min: valueMin,
    max: valueMax,
  };
};

// Sanitize values to be used the same for all helpers
const sanitizeValues = (values: unknown): Array<string | boolean | number> => {
  let sanitizedValues: Array<string | boolean | number>;

  if (!Array.isArray(values)) {
    sanitizedValues = [values as string];
  } else {
    sanitizedValues = values;
  }

  return sanitizedValues;
};

// Return a textual representation for boolean values
// @todo: Translate
const formatBooleanPropertyValues = (originalValues: Array<boolean>): string => {
  const values = sanitizeValues(originalValues);

  return values
    .map((value) => {
      return value ? 'Ja' : 'Nein';
    })
    .join(' | ');
};

// Return a textual representation for text values
const formatTextPropertyValues = (originalValues: Partial<IBaseProperty>): string => {
  const values = sanitizeValues(originalValues.values);

  const valueString = values.join(' | ');

  if ('' !== (originalValues.descriptor?.unit ?? '')) {
    return `${valueString} ${originalValues.descriptor?.unit ?? ''}`;
  }

  return valueString;
};

// Return the title(s) of an enum as readable string
const formatEnumPropertyValues = (property: Partial<IBaseProperty>): string => {
  const values = sanitizeValues(property.values);

  return values.map(value => {
    return property.descriptor?.enums?.find((enumValue) => enumValue.key === value)?.title ?? value;
  }).join(' | ');
};

// Convert integer property to a human readable (range) format including unit conversions.
const formatIntegerProperty = (property: Partial<IBaseProperty>): string => {
  const {
    min,
    max,
    unit
  } = getFormattedRangeFilterProperty(property);

  if (max === min) {
    return `${max} ${unit ?? ''}`;
  }

  return `${min} - ${max} ${unit ?? ''}`;
};

//
export const getFormattedPropertyValue = (property: Partial<IBaseProperty>): string => {
  switch (property?.descriptor?.type) {
  case PropertyType.Boolean:
    return formatBooleanPropertyValues(property.values as Array<boolean>);
  case PropertyType.Enum:
    return formatEnumPropertyValues(property);
  case PropertyType.Integer:
    return formatIntegerProperty(property);
  case PropertyType.Text:
    return formatTextPropertyValues(property);
  }

  throw new Error(`Invalid property passed to getFormattedPropertyValue: Type "${property.descriptor?.type}" is not configured to be formatted.`);
};

// Return an object with formatted values amd information about conversion (unit, divisor) for range filters
export const getFormattedRangeFilterProperty = (property: Partial<IBaseProperty>): IFormattedProperty | never => {
  if ('INTEGER' !== property?.descriptor?.type) {
    throw new Error('Invalid integer property provided for format as integers: ' + JSON.stringify(property));
  }

  const locale = 'de-DE';
  const integerFormat: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  const divisor = 1000;
  const threshold = 1000;

  let unit = property.descriptor.unit;
  let { min: valueMin, max: valueMax } = convertIntegerValues(property.values);

  // Convert mm to m if value is >= 500
  if ('mm' === property.descriptor.unit && threshold <= valueMin) {
    valueMin = valueMin / divisor;
    valueMax = valueMax / divisor;
    unit = 'm';
  }

  // Convert kg to t if value is >= 500
  if ('kg' === property.descriptor.unit && threshold <= valueMin) {
    valueMin = valueMin / divisor;
    valueMax = valueMax / divisor;
    unit = 'to';
  }

  return {
    isRange: true,
    min: new Intl.NumberFormat(locale, integerFormat).format(valueMin),
    max: new Intl.NumberFormat(locale, integerFormat).format(valueMax),
    unit,
    divisor
  };
};
