import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import { mediaQueries, spacingBottom, spacingTop } from '@digando/react-component-library';
import styled from '@emotion/styled';
import { ComponentContentInternalLinks } from '../../../@types/codegen/graphql';
import { ZoneTitle } from '../../zone-title';
import { StrapiLink } from '../StrapiLink/strapi-link';

type InternalLinksProps = ComponentContentInternalLinks & { sliceIndex: string };

const InternalLinks: FC<InternalLinksProps> = ({ heading, internalLinks }) => {

  // Do not render slice when no link items has been added.
  if (!internalLinks || 0 === (internalLinks?.length ?? 0)) {
    return <></>;
  }

  return (
    <ComponentWrapper data-debug-info={'internalLink'}>
      <ContentContainerMax>
        {heading?.title && <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} />}

        {internalLinks.map((internalLinkData, index) => {
          return (
            <CardWrapper key={`internal-link-item-${internalLinkData?.id ?? index}`}>
              <StrapiLink pageData={internalLinkData?.internalLinkPage} />
            </CardWrapper>
          );
        },
        )}
      </ContentContainerMax>
    </ComponentWrapper>
  );
};

const ComponentWrapper = styled.div`
  background-color: var(--background-light);
  position: relative;
  ${spacingBottom(8)};
  ${spacingTop(6)};
  height: max-content;
`;

const CardWrapper = styled.div`
  grid-column: auto / span 12;

  @media ${mediaQueries.tablet} {
    grid-column: auto / span 6;
  }

  picture {
    position: relative;
    width: 100%;
    height: 100%;

    div {
      position: unset !important;
      min-height: 100%;
    }
  }
`;

export default InternalLinks;
