import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import {
  Icon, spacingLeft, spacingRight,
  spacingTopBottom,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import { Carousel } from 'antd';
import { mediaQueries, size } from '../../../styles/media-queries';
import { ComponentContentTenantLogos } from '../../../@types/codegen/graphql';
import { usePageUrl } from '../../../lib/strapi/ssr-generate-page-url';
import Link from 'next/link';
import { ZoneTitle } from '../../zone-title';
import { RemoteImage } from '../../../lib/remote-image';

type TenantLogosProps = ComponentContentTenantLogos & { sliceIndex: string };

const TenantLogos: FC<TenantLogosProps> = ({ heading, tenants }) => {
  const { generatePageUrl } = usePageUrl();

  const settings = {
    slidesToShow: 4,
    nextArrow: <NextArrow><Icon size={'28px'} color={`var(--secondary-color)`} icon={'left-slide'} /></NextArrow>,
    prevArrow: <PrevArrow><Icon size={'28px'} color={`var(--secondary-color)`} icon={'left-slide'} /></PrevArrow>,
    dots: false,
    arrows: true,
    responsive: [
      { breakpoint: Number(size.desktop.slice(0, -2)), settings: { slidesToShow: 4 } },
      { breakpoint: Number(size.tablet.slice(0, -2)), settings: { slidesToShow: 3 } },
      {
        breakpoint: Number(size.mobile.slice(0, -2)),
        settings: { slidesToShow: 1, centerMode: true, focusOnSelect: true, arrows: false },
      },
    ],
  };

  const partnerLogos = tenants?.map((tenant, index) => {
    const page = tenant?.tenant?.data?.attributes;
    const image = tenant?.logo?.data?.attributes;

    return (
      <React.Fragment key={`internal-link-${tenant?.id ?? index}`}>
        {page?.slug
          ? <Link
            href={generatePageUrl(page?.__typename ?? '', page?.slug ?? '')}
          >
            <PartnersLogos>
              <RemoteImage
                sources={[]}
                src={image?.url ?? ''}
                alt={image?.alternativeText ?? ''}
                title={image?.alternativeText ?? ''}
                width={image?.width ?? 300}
                height={image?.height ?? 106}
              />
            </PartnersLogos>
          </Link>
          : <PartnersLogos>
            <RemoteImage
              sources={[]}
              src={image?.url ?? ''}
              alt={image?.alternativeText ?? ''}
              title={image?.alternativeText ?? ''}
              width={image?.width ?? 300}
              height={image?.height ?? 106}
            />
          </PartnersLogos>
        }
      </React.Fragment>
    )
  });

  return (
    <>
      <Section data-debug-info={'tenant-logos'}>
        <TitleSection>
          {heading?.title && <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} smallSpacing={true} />}
        </TitleSection>
      </Section>

      <Section carouselFix={true} noMobileSpacing>
        <PartnerLogosSectionWrapper>
          <CarouselWithLogo {...settings}>
            {partnerLogos}
          </CarouselWithLogo>
        </PartnerLogosSectionWrapper>
      </Section>
    </>
  );
};

export default TenantLogos;

const Arrow = styled.div`
  top: -50px !important;
  right: 20px !important;
  left: auto !important;
  border-radius: 50%;
  width: 28px !important;
  height: 28px !important;
  color: var(--font-color-light) !important;

  :hover {
    color: var(--secondary-color) !important;
  }
`;

const NextArrow = styled(Arrow)`
  transform: rotate(180deg);
`;

const PrevArrow = styled(Arrow)`
  transform: translateX(-40px);
`;

const Section = styled(ContentContainerMax)`
  ${spacingTopBottom(2)};

  @media print {

    img {
      page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-inside: avoid; /* or 'auto' */
    }
  }
`;

const TitleSection = styled.div`
  grid-column: auto / span 12;

  @media ${mediaQueries.mobile} {
    max-width: 80vw;
  }
`;

const PartnerLogosSectionWrapper = styled.div`
  display: block;
  grid-column: auto / span 12;
`;

const PartnersLogos = styled.div`
  height: 100px;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    margin: 0 auto;
    color: var(--font-color-light);
    filter: grayscale(100%);
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const CarouselWithLogo = styled(Carousel)`
  height: auto;

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center;
    }
  }

  @media ${mediaQueries.mobile} {

    .slick-track {

      .slick-slide {
        ${spacingLeft(2)};
        ${spacingRight(2)};
      }
    }
  }

  @media ${mediaQueries.tablet} {

    .slick-track {

      .slick-slide {
        ${spacingRight(2)};
        padding-left: 0;
      }
    }
  }
`;
