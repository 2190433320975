import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import styled from '@emotion/styled';
import { FontSecondarySemiBold, mediaQueries, spacingTopBottom } from '@digando/react-component-library';
import { GlobalSearchBar } from '../../../components/global-search/components/global-search-bar';
import { ComponentContentSearchBox } from '../../../@types/codegen/graphql';

type SearchBoxProps = ComponentContentSearchBox & { tenantKey: string; sliceIndex: string; };

const SearchBox: FC<SearchBoxProps> = ({ title, tenantKey }) => {
  return (
    <ContainerWrapper data-e2e-component={'SearchBox'}>
      <SearchWrapper>
        {title && (
          <SearchText>
            <p>{title}</p>
          </SearchText>
        )}
        <GlobalSearchWrapper>
          <GlobalSearchBar noSpacing={true} identifier={'tenant'} preselectedTenantKey={tenantKey} />
        </GlobalSearchWrapper>
      </SearchWrapper>
    </ContainerWrapper>
  );
};

export default SearchBox;

const ContainerWrapper = styled.div`
  background-color: var(--secondary-color);
`;

const SearchText = styled.div`
  align-self: center;
  min-width: 283px;
  color: var(--white);
  font-size: 28px;
  line-height: 33.6px;
  ${FontSecondarySemiBold};

  @media ${mediaQueries.tablet} {
    font-size: 40px;
    line-height: 48px;
  }
`;

const GlobalSearchWrapper = styled.div`
  align-self: center;
  width: inherit;
`;

const SearchWrapper = styled(ContentContainerMax)`
  width: 100%;
  height: max-content;
  grid-gap: 17px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
  ${spacingTopBottom(4)};

  picture {
    height: max-content;
  }

  @media ${mediaQueries.tablet} {
    grid-gap: 30px;
  }

  @media ${mediaQueries.laptopL} {
    flex-direction: row;
    grid-gap: 80px;
  }
`;
