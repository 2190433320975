import React, { FC } from 'react';
import { StrapiImageFragment } from '../../../../@types/codegen/graphql';
import { StrapiImageFormats } from '../../../../@types/strapi';
import { RemoteImage, RemoteImageSource } from '../../../../lib/remote-image';

interface ILinkImage {
  image: StrapiImageFragment | null;
  // This property will increase the used image-formats to be valid for the bigger container. Currently used in the onboarding section.
  isContainerMax?: boolean;
}

const getImageFormats = (imageFormats: StrapiImageFormats, isContainerMax: boolean): RemoteImageSource[] => {
  if (true === isContainerMax) {
    return [
      {
        maxWidth: '450px',
        src: imageFormats?.['425x315']?.url,
        srcDpr2: imageFormats?.['425x315_dpr2']?.url,
      },
      {
        maxWidth: '768px',
        src: imageFormats?.['720x535']?.url,
        srcDpr2: imageFormats?.['720x535_dpr2']?.url,
      },
      {
        maxWidth: '1024px',
        src: imageFormats?.['448x245']?.url,
        srcDpr2: imageFormats?.['425x315_dpr2']?.url,
      }, //Ratio: 1,348
      {
        maxWidth: '1240px',
        src: imageFormats?.['352x250']?.url,
        srcDpr2: imageFormats?.['352x250_dpr2']?.url,
      },
    ]
  }

  return [
    {
      maxWidth: '450px',
      src: imageFormats?.['425x315']?.url,
      srcDpr2: imageFormats?.['425x315_dpr2']?.url,
    },
    {
      maxWidth: '768px',
      src: imageFormats?.['720x535']?.url,
      srcDpr2: imageFormats?.['720x535_dpr2']?.url,
    },
    {
      maxWidth: '1024px',
      src: imageFormats?.['425x315']?.url,
      srcDpr2: imageFormats?.['425x315_dpr2']?.url,
    }, //Ratio: 1,348
    {
      maxWidth: '1240px',
      src: imageFormats?.['300x223']?.url,
      srcDpr2: imageFormats?.['300x223_dpr2']?.url,
    },
  ]
}


const LinkImage: FC<ILinkImage> = ({ image, isContainerMax = false }): React.ReactElement => {

  if (!image?.url) {
    return <></>;
  }

  const imageFormats: StrapiImageFormats = image.formats;

  return (
    <RemoteImage
      sources={getImageFormats(imageFormats, isContainerMax )}
      src={imageFormats?.['425x315']?.url}
      alt={image?.alternativeText ?? ''}
      title={image?.alternativeText ?? ''}
      width={imageFormats?.['425x315']?.width ?? 425}
      height={imageFormats?.['425x315']?.height ?? 315}
      lazyloading={true}
    />
  );
};

export default LinkImage;
