import React, { FC } from 'react';
import Link from 'next/link';
import { usePageUrl } from '../../../../lib/strapi/ssr-generate-page-url';

type StrapiLinkTypeProps = {
  url: string;
  pageName: string;
  title: string
  isButton?: boolean;
  tenantKey?: string;
  children?: React.ReactElement;
}

export const LinkType: FC<StrapiLinkTypeProps> = ({ url, pageName, title, isButton, tenantKey, children}) => {
  const { generatePageUrl } = usePageUrl();

  if (!url && !pageName) {
    return null;
  }

  const className = isButton ? 'button' : undefined;

  if ('external' === pageName) {
    let link;
    try {
      link = new URL(url ?? '');
    } catch (e) {
      link = new URL('https://' + url ??  '');
    }

    return (
      <a href={link.href} target={'_blank'} rel={'noreferrer'} className={className}>
        {children}
      </a>
    );
  } else if ('other' === pageName) {
    return (
      <a href={url}>
        {children}
      </a>
    )
  }

  return (
    <Link href={generatePageUrl(pageName, url, tenantKey)} title={title} className={className}>
      {children}
    </Link>
  );
};
