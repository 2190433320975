import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import {
  spacingBottom,
  spacingTopBottom,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import { mediaQueries } from '../../../styles/media-queries';
import { Newsletter } from '../../../components/newsletter/newsletter';
import { ComponentContentNewsletterSubscription } from '../../../@types/codegen/graphql';
import { LinkInTextConverter } from '../StrapiLink/link-in-text-converter';
import { ZoneTitle } from '../../zone-title';

type NewsletterSubscriptionProps = ComponentContentNewsletterSubscription & { sliceIndex: string; };

const NewsletterSubscription: FC<NewsletterSubscriptionProps> = ({ heading, description }) => (
  <Container data-debug-info={'NewsletterSubscription'}>
    <ContentContainerMax>
      <ContentLeft>
        {heading?.title && <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} />}
        <LinkInTextConverter text={description} />
      </ContentLeft>
      <FormContainer>
        <Newsletter identifier={'newsletter-slice'} />
      </FormContainer>
    </ContentContainerMax>
  </Container>
);


const Container = styled.div`
  ${spacingTopBottom(4)};
  background-color: var(--secondary-color);
  color: var(--white);
`;

const ContentLeft = styled.div`
  ${spacingBottom(2)};
  grid-column: auto / span 12;

  @media ${mediaQueries.tablet} {
    ${spacingBottom(0)};
    grid-column: auto / span 7;
  }

  h2 {
    font-size: var(--font-size-headline-h2);
    line-height: var(--line-height-headline-h2);
  }
`;


const FormContainer = styled.div`
  grid-column: auto / span 12;

  .ant-form {
    width: 100%;
  }

  @media ${mediaQueries.tablet} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-column: auto / span 5;
  }
`;

export default NewsletterSubscription;
