import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import {
  spacingTopBottom,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import { mediaQueries } from '../../../styles/media-queries';
import { useTranslation } from 'next-i18next';
import { ComponentContentContactSection } from '../../../@types/codegen/graphql';
import { ZoneTitle } from '../../zone-title';
import { TeamMemberCard } from '../../../components/elements/TeamMemberCard/TeamMemberCard';

type ContactSectionProps = ComponentContentContactSection & { sliceIndex: string; };

const ContactSection: FC<ContactSectionProps> = ({
  sliceIndex,
  heading,
  contacts,
}) => {
  const [t] = useTranslation(['slice-contact-section']);

  return (
    <SectionWrapper>
      <ContentContainerMax>
        {heading?.title && <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} />}

        {contacts?.data.map((item, index) => {
          return (
            <TeamMemberCardWrapper key={`${sliceIndex}_${item.id}_${index}`}>
              <TeamMemberCard
                phoneLabel={t('phone-label')}
                mailLabel={t('mail-label')}
                firstName={item.attributes?.firstName ?? ''}
                lastName={item.attributes?.lastName ?? ''}
                role={item.attributes?.position ?? ''}
                mail={item.attributes?.email ?? ''}
                phone={item.attributes?.phone ?? ''}
                image={item?.attributes?.picture?.data?.attributes ?? null}
              />
            </TeamMemberCardWrapper>
          );
        })
        }
      </ContentContainerMax>
    </SectionWrapper>
  );
};

const TeamMemberCardWrapper = styled.div`
  grid-column: auto / span 12;

  @media ${mediaQueries.mobile} {
    grid-column: auto / span 6;

    picture {
      height: 100%;

      div {
        height: 100%;
      }
    }

    img {
      object-fit: cover;
      height: 100%;
    }
  }

`;

const SectionWrapper = styled.div`
  ${spacingTopBottom(6)};
  background-color: var(--background-light);

  @media print {

    img {
      page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-inside: avoid; /* or 'auto' */
    }
  }
`;

export default ContactSection;
