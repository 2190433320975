import React, { FC, useEffect } from 'react';
import { Form } from 'antd';
import { useRouter } from 'next/router';

import dynamic from 'next/dynamic';
import { isOnSearchPage } from '../../../helper/is-on-search-page';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useHandleSubmit } from './use-handle-submit';
import { SearchInputTermTypes, SuggestionTypes, useGlobalSearchContext } from '../../../store/global-search.context';
import { useGlobalSearchValues, useSetGlobalSearch } from '../../../store/recoil/global-search';
import { useSetRecoilState } from 'recoil';
import {
  isSearchLocationOverlayOpenState,
} from '../../digando-overlay/search-location-overlay/states/search-location-overlay.states';
import { TermField } from './fields/term-field';
import { LocationField } from './fields/location-field';
import { FromDateField } from './fields/from-date-field';
import { ToDateField } from './fields/to-date-field';
import { GlobalSearchBarSearchButton } from './fields/search-button';
import styles from './global-search-bar.module.scss';
import classNames from 'classnames';

const DynamicSuggestionTerm = dynamic(() => import('./suggestion/suggestion-term'));
const DynamicSuggestionLocation = dynamic(() => import('./suggestion/suggestion-location'));
const DynamicSuggestionDate = dynamic(() => import('./suggestion/suggestion-date'));

interface IGlobalSearchBarProps {
  identifier: string;
  preselectedTenantKey?: string | null;
  noSpacing?: boolean;
}

/**
 * Wrapper around GlobalSearch to handle closeSuggestions in a hacky way.
 * @todo: The click outside handling / closeSuggestions handling has to be improved.
 */
const GlobalSearchBarContainerComponent: FC<IGlobalSearchBarProps> = (props) => {
  const {
    closeSuggestion,
  } = useGlobalSearchContext();
  const setIsSearchLocationOverlayOpen = useSetRecoilState(isSearchLocationOverlayOpenState);

  const containerRef = useDetectClickOutside({
    onTriggered: (event: Event): void => {
      /** Workaround for re-renderings with dynamic ids of form elements. */
      // check if a parent element has id starting with global-search
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - event is not typed correctly inside useDetectClickOutside
      const foundParent = event?.target?.closest('[id^=global-search], [class^=react-datepicker]');

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - event is not typed correctly inside useDetectClickOutside
      const id = event?.target?.id;

      // Close suggestion if click was outside the search bar, for that we check if the click was not on the search bar or the date picker
      if (!foundParent && id !== 'page-content-wrapper') {
        closeSuggestion();
        setIsSearchLocationOverlayOpen(false);
      }
    },
  });

  return (
    <div ref={containerRef} id='global-search-outer-container'>
      <GlobalSearchBarComponent {...props} />
    </div>
  );
};

export const GlobalSearchBarComponent: FC<IGlobalSearchBarProps> = ({
  identifier,
  preselectedTenantKey,
  noSpacing = false,
}) => {
  const globalSearchEndDateId = `global-search-end-date-${identifier}`;

  const router = useRouter();
  const { location, mainSearchVisible, term } = useGlobalSearchValues();
  const { setTerm } = useSetGlobalSearch();
  const {
    isSuggestionVisible,
    suggestionType,
  } = useGlobalSearchContext();

  const { handleSubmitSearch } = useHandleSubmit();
  const [form] = Form.useForm();

  useEffect(() => {
    if (undefined === router.query?.q) {
      setTerm({
        type: SearchInputTermTypes.TEXT,
        value: '',
      });
    }
  }, []);

  // Submit search when search term was changed
  useEffect(() => {
    if (SuggestionTypes.TERM !== suggestionType) {
      return;
    }

    if (isOnSearchPage(router.pathname) && SearchInputTermTypes.TEXT !== term?.type && '' !== (term?.value ?? '')) {
      handleSubmitSearch({ preselectedTenantKey });
    }
  }, [term?.value]);

  // Submit search when new location was selected
  useEffect(() => {
    if (SuggestionTypes.LOCATION !== suggestionType) {
      return;
    }

    if (isOnSearchPage(router.pathname) && ('' !== location?.placeId ?? '')) {
      handleSubmitSearch({ preselectedTenantKey });
    }
  }, [location?.placeId]);

  const suggestionWrapper = (): JSX.Element => {
    // Block suggestion for mobile view
    if ('undefined' === typeof window || window.innerWidth < 768) {
      return <></>;
    }

    return (
      <div
        className={classNames({
          [styles.suggestionWrapper]: true,
          [styles.noSpacing]: noSpacing,
          [styles.isOpen]: isSuggestionVisible,
          [styles.isMainSearchVisible]: mainSearchVisible,
        })}
        id='global-search-suggestion-wrapper'
      >
        <DynamicSuggestionTerm />
        <DynamicSuggestionLocation />
        <DynamicSuggestionDate endDateId={globalSearchEndDateId} />
      </div>
    );
  };

  return (
    <div className={classNames({
      [styles.globalSearchBarContainer]: true,
      [styles.noSpacing]: noSpacing,
    })}>
      <Form
        form={form}
        onKeyPress={(event): void => {
          if ('Enter' === event.key) {
            handleSubmitSearch({ preselectedTenantKey });
          }
        }}
      >
        <div className={styles.formWrapper}>
          <TermField identifier={identifier} />
          <LocationField identifier={identifier} preselectedTenantKey={preselectedTenantKey ?? null} />

          <div className={styles.fromToFieldWrapper}>
            <FromDateField identifier={identifier} />
            <ToDateField identifier={identifier} />
          </div>

          <div className={styles.searchButtonWrapper}>
            <GlobalSearchBarSearchButton preselectedTenantKey={preselectedTenantKey ?? null} />
          </div>
        </div>

        {(mainSearchVisible && isSuggestionVisible) && <div>{suggestionWrapper()}</div>}
      </Form>
    </div>
  );
};

export const GlobalSearchBar = React.memo(GlobalSearchBarContainerComponent, (prevProps, nextProps) => {
  return prevProps.preselectedTenantKey === nextProps.preselectedTenantKey && prevProps.identifier === nextProps.identifier;
});
