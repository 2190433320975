import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import {
  FontSecondarySemiBold,
  mediaQueries, spacingBottom, spacingCalculator,
  spacingLeft,
  spacingRight,
  spacingTopBottom,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import { ComponentContentTextWithBlock } from '../../../@types/codegen/graphql';
import { ZoneTitle } from '../../zone-title';
import { LinkInTextConverter } from '../StrapiLink/link-in-text-converter';
import styles from './text-with-fact-box.module.scss';

/**
 * @todo: Implement Link Resolver
 */

const TextBlock: FC<ComponentContentTextWithBlock & {sliceIndex: string;}> = (item) => {
  const { heading, text } = item;

  const boxes = item?.factBox?.filter(box => {
    return 0 < (box?.description?.length ?? 0) || null !== (box?.title ?? null);
  }) ?? [];

  return (
    <div data-debug-info={'text-with-factbox'} className={styles.textWithFactBoxes}>
      {(heading || text) && (
        <TextSection>
          <TextContainer withBox={1 == boxes.length} className={'text-container'}>
            {heading?.title && <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} smallSpacing={true} />}

            {text && (
              <LinkInTextConverter text={text}/>
            )}
          </TextContainer>

          {text && 1 == boxes.length && (
            <RightFactBox className={'fact-box'}>
              <FactTextTitle>{boxes?.[0]?.title}</FactTextTitle>
              <LinkInTextConverter text={boxes?.[0]?.description}/>
            </RightFactBox>
          )}
        </TextSection>
      )}

      {0 != boxes.length && (0 == text?.length || 1 < boxes.length) && (
        <ContentContainerMax>
          <BoxSection>
            <BoxContainer numberOfBoxes={boxes.length}>
              {boxes.map((item, i) => (
                <FactBox key={`FactBox-${i}`}>
                  <FactTextTitle>{(item?.title ?? '')}</FactTextTitle>
                  <LinkInTextConverter text={item?.description}/>
                </FactBox>
              ))}
            </BoxContainer>
          </BoxSection>
        </ContentContainerMax>
      )}
    </div>
  );
};

export default TextBlock;

const TextSection = styled(ContentContainerMax)`
  ${spacingBottom(0.5)};
  text-align: center;
  justify-self: center;
  justify-content: center;
  width: 100%;
`;

const TextContainer = styled.div <{ withBox: boolean }>`
  grid-column: auto / span ${(p): string => p.withBox ? '8' : '12'};
  text-align: left;

  @media all and (max-width: 800px) {
    grid-column: auto / span 12;
  }
`;

const BoxSection = styled.div`
  margin-top: ${spacingCalculator(1)};
  grid-column: auto / span 12;
`;

const BoxContainer = styled.div<{ numberOfBoxes: number }>`
  display: grid;
  grid-template-columns: repeat(${(p): number => p.numberOfBoxes * 3}, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
  justify-self: center;
  justify-content: center;

  @media all and (max-width: 800px) {    grid-template-columns: repeat(${(p): number => 1 == p.numberOfBoxes ? 3 : 6}, 1fr);
    grid-template-rows: repeat(${(p): number => p.numberOfBoxes / 2}, 1fr);
  }

  @media all and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const FactBox = styled.div`
  ${spacingTopBottom(1)};
  ${spacingLeft(3)};
  ${spacingRight(3)};
  background-color: var(--background-light);
  grid-column: auto / span 3;
  text-align: left;


  @media all and (max-width: 800px) {
    grid-column: auto / span 12;
    max-width: none;
  }

  @media ${mediaQueries.laptopL} {
    ${spacingTopBottom(2)};
    ${spacingLeft(4)};
    ${spacingRight(4)};
  }
`;

const RightFactBox = styled(FactBox)`
  grid-column: auto / span 4;
`;

const FactTextTitle = styled.div`
    ${spacingTopBottom(1)};
    ${FontSecondarySemiBold};
    font-size: var(--font-size-headline-h3);
    color: var(--secondary-color)
`;
