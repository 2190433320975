import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import styled from '@emotion/styled';
import {
  FontSecondarySemiBold,
  spacingBottom,
  spacingTopBottom,
} from '@digando/react-component-library';
import { mediaQueries } from '../../../styles/media-queries';
import { Button } from '@digando/common-ui';
import { ComponentContentCallToAction } from '../../../@types/codegen/graphql';
import { LinkType } from '../StrapiLink/components/link-type';
import { ZoneTitle } from '../../zone-title';

type CallToActionProps = ComponentContentCallToAction & { sliceIndex: string; }

const CallToAction: FC<CallToActionProps> = ({ heading, description, cta }) => {
  if (!cta || !cta.ctaLinkPage) {
    return null;
  }

  const buttonText = cta?.text || '';
  const url = cta?.ctaLinkPage?.slug || '';
  const pageName = cta?.ctaLinkPage?.contentType || '';
  const title = cta?.ctaLinkPage?.title || '';

  return (
    <Container data-e2e-component={'CallToAction'}>
      <ContentContainerMax>
        <ContentLeft>
          {heading?.title && <ZoneTitle title={heading.title} variant={heading.level} noSpacing={true} looksAs={'h2'} />}
          <p>{description}</p>
        </ContentLeft>
        <CtaContainer>
          <LinkType url={url} pageName={pageName} title={title}>
            <Button>
              {buttonText}
            </Button>
          </LinkType>
        </CtaContainer>
      </ContentContainerMax>
    </Container>
  );
};

const Container = styled.div`
  ${spacingTopBottom(4)};
  background-color: var(--secondary-color);
  color: var(--white);
`;

const ContentLeft = styled.div`
  ${spacingBottom(2)};
  grid-column: auto / span 12;

  h4 {
    font-size: var(--font-size-headline-h2);
    line-height: var(--line-height-headline-h2);
    ${FontSecondarySemiBold};
    padding: 0;
  }

  @media ${mediaQueries.tablet} {
    ${spacingBottom(0)};
    grid-column: auto / span 7;
  }
`;

const CtaContainer = styled.div`
  grid-column: auto / span 12;
  text-align: right;

  .button {
    width: 100%;
  }

  @media ${mediaQueries.tablet} {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 0;
    margin-left: auto;
    grid-column: auto / span 5;
  }
`;

export default CallToAction;
