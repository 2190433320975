import React, { ReactElement } from 'react';
import {
  Icon,
} from '@digando/react-component-library';
import styles from './digando-advantages-short.module.scss';
import classNames from 'classnames';

export interface IAdvantageText {
  advantage: string | JSX.Element;
}

export const DigandoAdvantagesShort = (props: IAdvantageText): ReactElement => {
  const { advantage } = props;

  return (
    <div className={classNames({
      [styles.DigandoAdvantagesContainer]: true,
      [styles.centered]: true,
    })}>
      <Icon className='digando-advantage-icon' icon={'check'} size={'20px'} color={`var(--secondary-contrast-font-color)`}/>
      {advantage}
    </div>
  );
};
