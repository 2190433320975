import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import { spacingCalculator, } from '@digando/react-component-library';
import { DigandoAdvantagesCard } from '../../../components/digando-advantages/digando-advantages-card';
import styled from '@emotion/styled';
import { mediaQueries } from '../../../styles/media-queries';
import { ComponentContentUspCards } from '../../../@types/codegen/graphql';
import { ZoneTitle } from '../../zone-title';

type UspCardProps = ComponentContentUspCards & { sliceIndex: string };

const UspCards: FC<UspCardProps> = ({ usps, heading}) => {
  function numberOfSlides(length: number): string | undefined {
    if (1 >= length) {
      return 'one-card';
    }

    if (2 === length) {
      return 'two-cards';
    }

    if (3 <= length) {
      return 'three-cards-or-more';
    }
  }

  if (!usps || 0 === usps.length) {
    return null;
  }

  return (
    <Container data-debug-info={'usp-cards'}>
      <ContentWrapper>
        {heading?.title && <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} />}

        <ContentContainerMax className={numberOfSlides(usps.length)}>
          {usps.map((usp, index: number) => {
            const image = usp?.icon?.data?.attributes;

            if (!image || !usp) {
              return null;
            }

            return (
              <DigandoAdvantagesCard
                image={image}
                key={index}
                title={usp.title ?? ''}
                text={usp.description ?? ''}
              />
            );
          })}
        </ContentContainerMax>
      </ContentWrapper>
    </Container>
  );
};

export default UspCards;

const Container = styled(ContentContainerMax)`
  margin-top: ${spacingCalculator(4)};
  margin-bottom: ${spacingCalculator(4)};
`;

const ContentWrapper = styled.div`
  grid-column: auto / span 12;
  width: 100%;

  .one-card {
    grid-template-columns: 1fr;

    @media ${mediaQueries.mobile} {
      width: 75%;
    }

    @media ${mediaQueries.tablet} {
      width: 50%;
    }

    @media ${mediaQueries.laptopL} {
      width: 25%;
    }
  }

  .two-cards {
    grid-template-columns: 1fr;

    @media ${mediaQueries.mobile} {
      grid-template-columns: 1fr 1fr;
    }

    @media ${mediaQueries.tablet} {
      width: 75%;
    }

    @media ${mediaQueries.laptopL} {
      width: 50%;
    }
  }

  .three-cards-or-more {
    grid-template-columns: 1fr;

    @media ${mediaQueries.mobile} {
      grid-template-columns: 1fr 1fr;
    }

    @media ${mediaQueries.laptop} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
`;
