import React, { FC } from 'react';
import FullWidthImage from './variants/full-width-image';
import NoImage from './variants/no-image';
import SideImage from './variants/side-image';
import { ComponentContentTextWithImage } from '../../../@types/codegen/graphql';

export type TextWithImageProps = ComponentContentTextWithImage & { 
  sliceIndex: string;
  fitParentContainer?: boolean;};

const variants = {
  fullWidthImage: FullWidthImage,
  noImage: NoImage,
  sideImage: SideImage,
};

const TextWithImage: FC<TextWithImageProps> = (props) => {
  const isCenter = ['top', 'bottom'].includes(props.imagePosition ?? '');
  const hasImage = props.image?.data?.attributes?.url ? true : false;

  let Component = variants['sideImage'];

  if (true === isCenter && true === hasImage) {
    Component = variants['fullWidthImage'];
  }

  if (false === hasImage) {
    Component = variants['noImage'];
  }

  return <Component {...props} />;
};

export default TextWithImage;
