import React, { FC } from 'react';
import styles from './zone-title.module.scss';
import classNames from 'classnames';

export interface ITitleWrapperStylingProps {
  smallSpacing?: boolean;
  noSpacing?: boolean;
}

type AllowedVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface ISliceTitleProps extends ITitleWrapperStylingProps {
  title: string;
  variant: AllowedVariants;
  looksAs?: AllowedVariants;
}

const getHeadingTag = (variant: AllowedVariants) => {
  switch (variant) {
  case 'h1':
    return 'h1';
  case 'h2':
    return 'h2';
  case 'h3':
    return 'h3';
  case 'h4':
    return 'h4';
  case 'h5':
    return 'h5';
  case 'h6':
    return 'h6';
  default:
    return 'h2';
  }
}

export const ZoneTitle: FC<ISliceTitleProps> = ({
  title,
  variant = 'h2',
  smallSpacing = false,
  noSpacing = false,
  looksAs = variant,
}) => {
  const HeaderTag = getHeadingTag(variant) as keyof JSX.IntrinsicElements;

  return (
    <div className={classNames({
      [styles.zoneTitle]: true,
      [styles.noSpacing]: noSpacing,
      [styles.smallSpacing]: smallSpacing,
    })}>
      <HeaderTag className={classNames({
        [styles.looksAsH1]: 'h1' === looksAs,
        [styles.looksAsH2]: 'h2' === looksAs,
        [styles.looksAsH3]: 'h3' === looksAs,
        [styles.looksAsH4]: 'h4' === looksAs,
        [styles.looksAsH5]: 'h5' === looksAs,
        [styles.looksAsH6]: 'h6' === looksAs,
      })}>{title}</HeaderTag>
    </div>
  );
};
