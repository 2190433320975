import React, { FC } from 'react';
import { TextWithImageProps } from '../index';
import { Container, Grid } from '../styles/container';
import styled from '@emotion/styled';
import { SliceTitleWrapper } from '../styles/title';
import { CtaLink } from '../components/cta-link';
import { ZoneTitle } from '../../../zone-title';
import { RemoteImage } from '../../../../lib/remote-image';
import { StrapiImageFormats } from '../../../../@types/strapi';
import { LinkInTextConverter } from '../../StrapiLink/link-in-text-converter';

const FullWidthImage: FC<TextWithImageProps> = (props) => {
  const { heading, description, imagePosition, transparentBackground, image: imageRes, cta } = props;
  const hasBackground = false === transparentBackground;
  const isBottomImage = 'bottom' === imagePosition;
  const image = imageRes?.data?.attributes;
  const imageFormats: StrapiImageFormats = image?.formats;

  return (
    <Container hasBackground={hasBackground} data-debug-info={'text-with-image_full-width-image'}>
      <Grid fitParentContainer={props.fitParentContainer}>
        {heading?.title && (
          <SliceTitleWrapper>
            <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} smallSpacing={true} />
          </SliceTitleWrapper>
        )}

        {(description || cta?.ctaLinkPage?.slug) && (
          <DescriptionContainer>
            <LinkInTextConverter text={description} />

            <CtaLink {...props} />
          </DescriptionContainer>
        )}

        {image && imageFormats && (
          <ImageContainer isBottomImage={isBottomImage}>
            <RemoteImage
              sources={[
                {
                  maxWidth: '500px',
                  src: imageFormats['425x0']?.url,
                  srcDpr2: imageFormats['425x0_dpr2']?.url,
                },
                {
                  maxWidth: '900px',
                  src: imageFormats['768x0']?.url,
                  srcDpr2: imageFormats['768x0_dpr2']?.url,
                },
                {
                  maxWidth: '1300px',
                  src: imageFormats['1160x0']?.url,
                  srcDpr2: imageFormats['1160x0_dpr2']?.url,
                },
              ]}
              src={imageFormats['425x0']?.url}
              alt={image?.alternativeText ?? ''}
              title={image?.alternativeText ?? ''}
              priority={true}
              width={imageFormats['425x0']?.width ?? 1442}
              height={imageFormats['425x0']?.height ?? 890}
            />
          </ImageContainer>
        )}
      </Grid>
    </Container>
  );
};


const DescriptionContainer = styled.div`
  grid-column: auto / span 12;
  order: 1;
`;

const ImageContainer = styled.div<{ isBottomImage: boolean; }>`
  grid-column: auto / span 12;
  order: ${(p): number => p.isBottomImage ? 2 : 0};

  img {
    width: 100%;
    height: auto;
  }
`;

export default FullWidthImage;
