import React, { FC } from 'react';
import styled from '@emotion/styled';
import { FontSecondarySemiBold } from '../../../styles/fonts';

export interface IProductTitleProps {
  productTitle: string;
}

export const ProductTitle: FC<IProductTitleProps> = (props) => {
  const { productTitle } = props;


  return <StyledTitle> {productTitle} </StyledTitle>;
};

const StyledTitle = styled.p`
    ${FontSecondarySemiBold};
    font-size: var(--font-size-headline-h4);
    line-height: 24px;
    color: var(--primary-color);
`;
