import React, { FC, useEffect, useState } from 'react';
import { ContentContainerMax } from '../../layout/container';
import {
  FontSecondaryRegular,
  FontSecondarySemiBold,
  spacingBottom,
  spacingCalculator, spacingRight,
  spacingTop,
} from '@digando/react-component-library';
import { Select } from '@digando/common-ui';
import styled from '@emotion/styled';
import { mediaQueries } from '../../styles/media-queries';
import { css, SerializedStyles } from '@emotion/react';
import type { SelectValue } from 'antd/es/select';
import { ComponentContentRecommendedCategories } from '../../@types/codegen/graphql';

export const FilterMenu: FC<{
  onFilterChange: (activeFilter: string) => void;
  items: ComponentContentRecommendedCategories['categories']
}> = (props) => {
  const [activeFilter, setActiveFilter] = useState<string>(props.items?.[0]?.id ?? '');

  useEffect(() => {
    props.onFilterChange(activeFilter);
  }, [activeFilter]);

  const onChange = (value: SelectValue): void => {
    setActiveFilter(value as string);
  };

  return (
    <>
      <ContentContainerMax>
        <SelectFilterMobile>
          <FilterMenuWrapper>
            <Select
              id={'filter'}
              name={'filter'}
              noSelectionText={''}
              noOptionsText={''}
              onChange={onChange}
              defaultValue={activeFilter}
              options={props.items?.map((item) => {
                return {
                  value: item?.id ?? '',
                  name: item?.title ?? '',
                  key: item?.id ?? '',
                };
              }) ?? []}
            />
          </FilterMenuWrapper>
        </SelectFilterMobile>
      </ContentContainerMax>

      <ContentContainerMax>
        <FilterMenuWrapper>
          <FilterButtonsWrapperDesktop>
            {props.items.map((item) => {
              return (
                <FilterButton
                  onClick={(): void => onChange(item?.id ?? '')} key={item?.id ?? ''}
                  isActive={activeFilter === item?.id ?? ''}
                >
                  {item?.title ?? 'ddd'}
                </FilterButton>
              );
            })}
          </FilterButtonsWrapperDesktop>
        </FilterMenuWrapper>
      </ContentContainerMax>
    </>
  );
};

const FilterMenuWrapper = styled.div`
  width: 100%;
  grid-column: 1 / 13;

  @media ${mediaQueries.tablet} {
    margin-bottom: ${spacingCalculator(4)};
    border-bottom: 3px solid var(--border-light-color);
  }
`;

const activeStyles = css`
  color: var(--secondary-color);
  outline: 0 !important;
  border-bottom: 3px solid var(--secondary-color) !important;
  background-color: transparent;
`;

const inactiveStyles = css``;

const FilterButton = styled.button<{ isActive: boolean }>`
  ${(p): SerializedStyles => {
    return p.isActive ? activeStyles : inactiveStyles;
  }}
`;

const SelectFilterMobile = styled.div`
  ${spacingBottom(4)};
  ${spacingTop(2)};
  width: 100%;
  grid-column: 1 / span 12;

  .ant-select {
    max-width: 95vw;
    width: 100%;
  }

  .ant-select-arrow {
    color: var(--primary-color);
    ${spacingRight(8)}
  }

  @media ${mediaQueries.tablet} {
    display: none;
  }
`;

const FilterButtonsWrapperDesktop = styled.div`
  display: none;

  @media ${mediaQueries.tablet} {
    margin-right: 80px;
    grid-column: auto / span 12;
    display: flex;
    margin-bottom: -3px;
  }

  button {
    margin-right: ${spacingCalculator(1)};
    padding-bottom: 15px;
    ${FontSecondaryRegular};
    font-size: var(--font-size-headline-h4);
    background-color: transparent;
    border: none;

    :hover {
      color: var(--secondary-color);
      outline: 0 !important;
      cursor: pointer;
    }

    :focus {
      ${FontSecondarySemiBold};
      color: var(--secondary-color);
      border-bottom: 3px solid var(--secondary-color);
      outline: 0 !important;
    }
  }
`;

export default FilterMenu;
