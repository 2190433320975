import React, { FC, useEffect } from 'react';
import { HubspotProvider, useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import styled from '@emotion/styled';

import { ContentContainerMax } from '../../../layout/container';
import { ComponentContentHubspotForm } from '../../../@types/codegen/graphql';
import { ZoneTitle } from '../../zone-title';

type HubspotFormProps = ComponentContentHubspotForm & { sliceIndex: string; };

const HubspotForm: FC<HubspotFormProps> = ({ heading, hubspotFormId, hubspotPortalId }) => {

  const { formCreated } = useHubspotForm({
    portalId: hubspotPortalId ?? '',
    formId: hubspotFormId ?? '',
    target: '#hubspot-form',
  });

  const calculateFormHeight = (form: HTMLObjectElement) => {
    let height = 0;
    let heightRepeat = 0;

    const getFormHeeightIntervalID = setInterval(() => {
      if(form.contentDocument) {
        if(heightRepeat > 3 && height === form.contentDocument.body.offsetHeight) {
          form.style.height = `${height}px`;
          clearInterval(getFormHeeightIntervalID);
        }
        else if(height === form.contentDocument.body.offsetHeight) {
          heightRepeat++;
        }
        else {
          height = form.contentDocument.body.offsetHeight;
          heightRepeat = 1;
        }
      }
    }, 100);
  };

  useEffect(() => {
    if (formCreated) {
      let iframe: HTMLObjectElement | null = null;

      const getIframeIntervalId = setInterval(()  => {
        iframe = document.querySelector('#hubspot-form iframe') as HTMLObjectElement;

        if(iframe) {
          calculateFormHeight(iframe);
          clearInterval(getIframeIntervalId);
        }
      }, 100);
    }
  },[formCreated]);

  return (
    <>
      <ContentContainerMax>
        {heading?.title && <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} />}
        <FormWrapper>
          <div id="hubspot-form" />
        </FormWrapper>
      </ContentContainerMax>
    </>
  );
};

const HubspotFormWrapper: FC<HubspotFormProps> = (props) => {
  return (
    <>
      <HubspotProvider>
        <HubspotForm {...props} />
      </HubspotProvider>
    </>
  );
};

export default HubspotFormWrapper;

const FormWrapper = styled(ContentContainerMax)`
  grid-template-columns: 1fr;
  grid-column: auto / span 12;
`;
