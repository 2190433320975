
interface IImageParams {
  url: string;
  w: string;
  h?: string;
  q?: string;
  blur?: string;
  crop?: boolean
}

export const fallbackImageUrl = (configs: IImageParams): string => {
  const { url, w, h, q, blur, crop = true } = configs;
  const imageUrl = new URL(url);

  imageUrl.searchParams.set('w', w);
  h && imageUrl.searchParams.set('h', h);
  q && imageUrl.searchParams.set('q', q);
  blur && imageUrl.searchParams.set('blur', blur);
  crop && imageUrl.searchParams.set('fit', 'crop');

  return imageUrl.toString();
}
