import React, { FC } from 'react';
import { TextWithImageProps } from '../index';
import { Container, Grid } from '../styles/container';
import styled from '@emotion/styled';
import { SliceTitleWrapper } from '../styles/title';
import { CtaLink } from '../components/cta-link';
import { ZoneTitle } from '../../../zone-title';
import { LinkInTextConverter } from '../../StrapiLink/link-in-text-converter';

const NoImage: FC<TextWithImageProps> = (props) => {
  const { heading, transparentBackground, description } = props;
  const hasBackground = false === transparentBackground ?? false

  return (
    <Container hasBackground={hasBackground} data-debug-info={'text-with-image_no-image'}>
      <Grid fitParentContainer={props.fitParentContainer}>
        {heading?.title && (
          <SliceTitleWrapper>
            <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} smallSpacing={true} />
          </SliceTitleWrapper>
        )}

        {description && (
          <DescriptionContainer>
            <LinkInTextConverter text={description} />

            <CtaLink {...props} />
          </DescriptionContainer>
        )}
      </Grid>
    </Container>
  );
};

const DescriptionContainer = styled.div`
  grid-column: auto / span 12;
  order: 1;
`;

export default NoImage;
