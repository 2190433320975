import React, { FC } from 'react';
import { ProductPageLink } from '../product/product-page-link';
import { RecommendedProductsItemFragment } from '../../@types/codegen/graphql';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { generateProductTitle } from '../search-page/helper/generate-product-title';
import { readablePrice, spacingCalculator } from '@digando/react-component-library';
import { getFormattedPropertyValue } from '../../helper/readable-product-property';
import { RemoteImage } from '../../lib/remote-image';
import { TenantLogo } from '../../lib/image/tenant-logo';
import { IProductPrice, ProductPrice } from '../elements/ProductCard/ProductPrice';
import { ProductCard } from '../elements/ProductCard/ProductCard';
import { fallbackImageUrl } from '../../helper/fallback-image';
import { DrawerStep } from '../sidebar/interfaces/drawer.interfaces';
import { useAuthContext } from '../../store/auth.context';

const LoginHint: FC = () => {
  const [t] = useTranslation(['common', 'search']);
  const { setSignInStep, setIsSignInVisible } = useAuthContext();

  return (
    <LoginHintWrapper>
      {t('search:login-hint')}

      <span onClick={(): void => {
        setSignInStep(DrawerStep.SIGN_IN);
        setIsSignInVisible(true);
      }}>
        {t('search:login-hint-link-text')}
      </span>
    </LoginHintWrapper>
  );
};

interface SmallProductCardProps {
  product: Pick<RecommendedProductsItemFragment, 'properties' | 'type' | 'prices' | 'productImage'>;
  rentalStation: Pick<RecommendedProductsItemFragment['rentalStation'], 'id' | 'tenant'>;
}

const SmallProductCardComponent: FC<SmallProductCardProps> = ({ product, rentalStation }) => {
  const [t] = useTranslation(['search']);
  const { type } = product;

  const properties = product.properties
    .filter(property => property.isRolloverProperty)
    .slice(0, 3)
    .map((property, index) => ({
      id: index,
      name: property.descriptor.title ?? property.key,
      value: getFormattedPropertyValue({
        descriptor: property.descriptor,
        values: property.value,
      }),
    }));

  const prices = product.prices
    .slice(0)
    .map<IProductPrice>(price => ({
      price: `${readablePrice(price.price)} / ${t('common:day')}`,
      days: price.minDays,
    })) ?? [];

  const productImage = product?.productImage ?? product.type.images[0];

  const productPrice = (
    <PricesContainer>
      {0 < prices.length ? (
        <ProductPrice
          prices={prices}
          pricePerDayLabel={t('search:day')}
          fromLabel={t('search:from-label')}
          renderTooltipDays={(days: number): React.ReactElement => <>{t('search:day-label', { count: days })}</>}
          detailsLabel={t('search:price-details-label')}
          link={null}
        />
      ) : (
        <LoginHint/>
      )}
    </PricesContainer>
  );

  return (
    <ProductPageLink
      href={product.type.productPageUrl}
      variants={[]}
      rentalStationId={rentalStation.id}
      tenantKey={rentalStation.tenant.key}
      title={generateProductTitle(type, [])}
    >
      <ProductCard
        isFactListExpanded={true}
        tagName={type.mainCategory?.title ?? ''}
        productTitle={generateProductTitle(type, [])}
        productPrice={productPrice}
        productImg={
          <ProductImage>
            <RemoteImage
              sources={[
                { maxWidth: '440px', src: fallbackImageUrl({url: productImage.url, w: '330', q: '90', crop: false}) },
                { maxWidth: '1024px', src: fallbackImageUrl({url: productImage.url, w: '450', q: '90', crop: false}) },
              ]}
              src={fallbackImageUrl({url: productImage.url, w: '330', q: '90'})}
              alt={productImage.alt}
              title={generateProductTitle(type, [])}
              blurSrc={fallbackImageUrl({url: productImage.url, w: '330', blur: '300'})}
              width={productImage.metadata.width}
              height={productImage.metadata.height}
            />
          </ProductImage>
        }
        partnerLogo={
          <>
            {rentalStation?.tenant?.logo && (
              <TenantLogoContainer>
                <TenantLogo logo={rentalStation.tenant.logo} height={30} width={130} />
              </TenantLogoContainer>
            )}
          </>
        }
        productInfoArray={properties}
      />
    </ProductPageLink>
  );
};

const PricesContainer = styled.div`
  max-width: 57%;
`;

const TenantLogoContainer = styled.div`
  position: relative;
  height: 30px;
  margin-left: ${spacingCalculator(4)};
  max-width: 130px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const LoginHintWrapper = styled.div`
  min-height: 44px;
`;

// @todo: Move to CL and reuse it on mobile search result
const ProductImage = styled.div`
  width: 100%;
  height: 280px;
  cursor: pointer;

  * {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const SmallProductCard = React.memo(SmallProductCardComponent, (prevProps, nextProps) => {
  return nextProps.product.type.id === prevProps.product.type.id
    && prevProps.rentalStation.id === nextProps.rentalStation.id
    && prevProps.product.prices.length === nextProps.product.prices.length;
});
