import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import styled from '@emotion/styled';
import {
  FontSecondarySemiBold,
  mediaQueries,
  spacingBottom,
  spacingLeft,
  spacingTopBottom,
} from '@digando/react-component-library';
import { ComponentContentImagePresentation } from '../../../@types/codegen/graphql';
import { StrapiImageFormats } from '../../../@types/strapi';
import { RemoteImage } from '../../../lib/remote-image';

type ImagePresentationProps = ComponentContentImagePresentation & { sliceIndex: string };

const ImagePresentation: FC<ImagePresentationProps> = ({ images }) => {
  return (
    <MainWrapper>
      {images.map((data, i) => {
        const image = data?.image?.data?.attributes;
        const imageFormats: StrapiImageFormats = image?.formats;

        if (!image || !imageFormats) {
          return null;
        }

        return (
          <div key={i}>
            <Title><>{data.title}</>
            </Title>
            <ImageContainer>
              <RemoteImage
                sources={[
                  {
                    maxWidth: '440x',
                    src: imageFormats['380x280'].url,
                    srcDpr2: imageFormats['380x280_dpr2'].url,
                  },
                  {
                    maxWidth: '768',
                    src: imageFormats['730x400'].url,
                    srcDpr2: imageFormats['730x400_dpr2'].url,
                  },
                  {
                    maxWidth: '1024',
                    src: imageFormats['448x245'].url,
                    srcDpr2: imageFormats['448x245_dpr2'].url,
                  },
                  {
                    maxWidth: '1400px',
                    src: imageFormats['548x300'].url,
                    srcDpr2: imageFormats['548x300_dpr2'].url,
                  },
                ]}
                src={imageFormats['380x280'].url}
                alt={image?.alternativeText ?? ''}
                title={image?.alternativeText ?? ''}
                width={imageFormats['380x280'].width}
                height={imageFormats['380x280'].height}
              />
            </ImageContainer>
          </div>
        );
      })}
    </MainWrapper>
  );
};

export default ImagePresentation;

const ImageContainer = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;

const MainWrapper = styled(ContentContainerMax)`
  ${spacingTopBottom(6)};
  grid-row-gap: 1vw;
  grid-template-columns: repeat(1, 1fr);

  @media ${mediaQueries.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media print {

    img {
      page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-inside: avoid; /* or 'auto' */
    }
  }
`;

const Title = styled.div`
    ${FontSecondarySemiBold};
    ${spacingBottom(1)};
    ${spacingLeft(2)};
    font-size: var(--font-size-headline-h3);
`;
