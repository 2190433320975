import { AllZonesFragment } from '../@types/codegen/graphql';
import TextWithFactBox from './layers/TextWithFactBox/index';
import ActionButton from './layers/ActionButton/index';
import { FC } from 'react';
import CallToAction from './layers/CallToAction';
import CategoryOverview from './layers/CategoryOverview';
import FaqSection from './layers/FaqSection';
import QuoteBlock from './layers/QuoteBlock';
import NewsletterSubscription from './layers/NewsletterSubscription';
import ContactSection from './layers/ContactSection';
import SearchBox from './layers/SearchBox';
import RecommendedProducts from './layers/RecommendedProducts';
import HubspotForm from './layers/HubspotForm';
import ImagePresentation from './layers/ImagePresentation';
import PrivacySettings from './layers/PrivacySettings';
import TenantLogos from './layers/TenantLogos';
import Testimonials from './layers/Testimonials';
import TextWithImage from './layers/TextWithImage';
import UspCards from './layers/UspCards';
import UspDigando from './layers/UspDigando';
import YoutubeVideo from './layers/YoutubeVideo';
import InternalLinks from './layers/InternalLinks';
import Onboarding from './layers/Onboarding';
import UspProduct from './layers/UspProduct';
import RentalStations from './layers/RentalStations';

const zonesMapping: { [key: string]: FC<{sliceIndex: string;}> } = {
  'ComponentContentTextWithBlock': TextWithFactBox as FC<{sliceIndex: string;}>,
  'ComponentContentActionButton': ActionButton as FC<{sliceIndex: string;}>,
  'ComponentContentCallToAction': CallToAction as FC<{sliceIndex: string;}>,
  'ComponentContentCategoryOverview': CategoryOverview as FC<{sliceIndex: string;}>,
  'ComponentContentFaq': FaqSection as FC<{sliceIndex: string;}>,
  'ComponentContentQuoteBlock': QuoteBlock as FC<{sliceIndex: string;}>,
  'ComponentContentNewsletterSubscription': NewsletterSubscription as FC<{sliceIndex: string;}>,
  'ComponentContentContactSection': ContactSection as FC<{sliceIndex: string;}>,
  'ComponentContentSearchBox': SearchBox as FC<{sliceIndex: string;}>,
  'ComponentContentRecommendedCategories': RecommendedProducts as FC<{sliceIndex: string;}>,
  'ComponentContentHubspotForm': HubspotForm as FC<{sliceIndex: string;}>,
  'ComponentContentImagePresentation': ImagePresentation as FC<{sliceIndex: string;}>,
  'ComponentContentPrivacySettings': PrivacySettings as FC<{sliceIndex: string;}>,
  'ComponentContentTenantLogos': TenantLogos as FC<{sliceIndex: string;}>,
  'ComponentContentTestimonials': Testimonials as FC<{sliceIndex: string;}>,
  'ComponentContentTextWithImage': TextWithImage as FC<{sliceIndex: string;}>,
  'ComponentContentUspCards': UspCards as FC<{sliceIndex: string;}>,
  'ComponentContentUspDigando': UspDigando as FC<{sliceIndex: string;}>,
  'ComponentContentYoutubeVideo': YoutubeVideo as FC<{sliceIndex: string;}>,
  'ComponentContentInternalLinks': InternalLinks as FC<{sliceIndex: string;}>,
  'ComponentContentOnBoarding': Onboarding as FC<{sliceIndex: string;}>,
  'ComponentContentUspProduct': UspProduct as FC<{sliceIndex: string;}>,
  'ComponentContentRentalStations': RentalStations as FC<{sliceIndex: string;}>,
};

type ZonesProps = {
  zones: AllZonesFragment[];
}

export const Zones: FC<ZonesProps> = ({ zones }) => {
  return (<>
    {zones.map((zone, index) => {
      if (!zone?.__typename) {
        return null;
      }

      const Component = zonesMapping[zone.__typename];

      if (null === Component) {
        // eslint-disable-next-line no-console
        console.error(`Add ${zone.__typename ?? '???'} to import list on Zones-Mapper`);

        return null;
      }

      if (Component) {
        return <Component key={index} sliceIndex={`zone-${index}`} {...zone} />;
      }

      return null;
    })}
  </>);
};
