import { IProductPrice } from '../components/elements/ProductCard/ProductPrice';

const sortPricesByDays = (prices: IProductPrice[]): void => {
  prices.sort((a, b) => {
    if (a.days > b.days) {
      return 1;
    } else if (a.days < b.days) {
      return -1;
    }

    return 0;

  });
};

export { sortPricesByDays };
