import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import {
  FontSecondarySemiBold,
  spacingBottom,
  FontPrimaryRegular,
  spacingTop,
  spacingLeft,
  spacingRight,
} from '@digando/react-component-library';
import Image from 'next/image';
import { StrapiImageFragment } from '../../@types/codegen/graphql';

export interface IAdvantageText {
  title?: string;
  text: string;
  image?: StrapiImageFragment;
}

export const DigandoAdvantagesCard = (props: IAdvantageText): ReactElement => {
  const { title, text, image } = props;

  return (
    <Container>
      <AdvantageIcon>
        {image?.url && (
          <Image
            alt={image.alternativeText ?? ''}
            src={image.url}
            width={image?.width ?? 0}
            height={image?.height ?? 0}
            unoptimized={true}
          />
        )}
      </AdvantageIcon>
      <CardTitle>{title}</CardTitle>
      <p>{text}</p>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid var(--border-light-color);
  text-align: center;

  > p {
    ${FontPrimaryRegular};
    ${spacingBottom(5)};
    ${spacingLeft(2)};
    ${spacingRight(2)};
    font-size: var(--font-size-text-small);
    color: var(--font-color-light);
  }

  > div {
    margin-left: auto;
    margin-right: auto;
  }
`;

const CardTitle = styled.div`
  ${spacingLeft(4)};
  ${spacingRight(4)};
  ${FontSecondarySemiBold};
  ${spacingBottom(1)};
  font-size: var(--font-size-headline-h3);
  color: var(--secondary-color);
`;

const AdvantageIcon = styled.div`
  ${spacingTop(5)};
  ${spacingBottom(3)};
  width: 17%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
`;
