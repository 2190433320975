import styled from '@emotion/styled';
import React, { FC } from 'react';
// eslint-disable-next-line
import { ContentContainerMax } from 'src/layout/container';
import { ComponentContentYoutubeVideo } from '../../../@types/codegen/graphql';

type YoutubeVideoProps = ComponentContentYoutubeVideo & { sliceIndex: string };

const YoutubeVideo: FC<YoutubeVideoProps> = ({ youtubeLink }) => {
  if (!youtubeLink) {
    return null;
  }

  const url = new URL(youtubeLink);

  if(!url || !url.searchParams.get('v')){
    return null;
  }

  return (
    <StyledContentContainerMax>
      <StyledIframe
        src={`https://www.youtube.com/embed/${url.searchParams.get('v')}?rel=0`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      >
      </StyledIframe>
    </StyledContentContainerMax>
  );
};

const StyledContentContainerMax = styled(ContentContainerMax)`
  position: relative;
  display: flex;
  justify-content: center;
`;

const StyledIframe = styled.iframe`
  aspect-ratio: 16 / 9;
  width: 100%;
`;


export default YoutubeVideo;
