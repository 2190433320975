import React, { FC } from 'react';
import { TextWithImageProps } from '../index';
import { ContentContainerMax } from '../../../../layout/container';
import styled from '@emotion/styled';
import { SliceTitleWrapper } from '../styles/title';
import { spacingCalculator } from '@digando/react-component-library';
import { CtaLink } from '../components/cta-link';
import { mediaQueries } from '../../../../styles/media-queries';
import { ZoneTitle } from '../../../zone-title';
import { StrapiImageFormats } from '../../../../@types/strapi';
import { RemoteImage } from '../../../../lib/remote-image';
import { LinkInTextConverter } from '../../StrapiLink/link-in-text-converter';

const SideImage: FC<TextWithImageProps> = (props) => {
  const { transparentBackground, cta, imagePosition, image: imageRes, heading, description } = props;
  const image = imageRes?.data?.attributes;
  const imageFormats: StrapiImageFormats = image?.formats;
  const hasBackground = false === transparentBackground;
  const isRightImage = 'right' === imagePosition ?? '';

  return (
    <SideImageContainer hasBackground={hasBackground} noMobileSpacing={true} data-debug-info={'text-with-image_side-image'}>
      {(description || cta?.ctaLinkPage) && (
        <DescriptionContainer isRightImage={isRightImage}>
          {heading?.title && (
            <SliceTitleWrapper>
              <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} smallSpacing={true} />
            </SliceTitleWrapper>
          )}

          <LinkInTextConverter text={description} />

          <CtaLink {...props} />
        </DescriptionContainer>
      )}

      {image?.url && (
        <ImageContainer isRightImage={isRightImage}>
          <RemoteImage
            sources={[
              {
                maxWidth: '440px',
                src: imageFormats['425x0']?.url ?? image.url,
                srcDpr2: imageFormats['425x0_dpr2']?.url ?? image.url,
              },
              {
                maxWidth: '768px',
                src: imageFormats['768x0']?.url ?? image.url,
                srcDpr2: imageFormats['768x0_dpr2']?.url ?? image.url,
              },
              {
                maxWidth: '1000px',
                src: imageFormats['425x0']?.url ?? image.url,
                srcDpr2: imageFormats['425x0_dpr2']?.url ?? image.url,
              },
              {
                maxWidth: '1400px',
                src: imageFormats['584x0']?.url ?? image.url,
                srcDpr2: imageFormats['584x0_dpr2']?.url ?? image.url,
              },
            ]}
            src={imageFormats['425x0']?.url}
            alt={image?.alternativeText ?? ''}
            title={image?.alternativeText ?? ''}
            priority={true}
            width={imageFormats['425x0']?.width ?? 584}
            height={imageFormats['425x0']?.height ?? 440}
          />
        </ImageContainer>
      )}
    </SideImageContainer>
  );
};

const SideImageContainer = styled(ContentContainerMax)<{ hasBackground: boolean }>`
  background-color: ${(p): string => p.hasBackground ? `var(--background-light)` : 'transparent'};
  margin-top: ${(p): string => p.hasBackground ? '0' : `${spacingCalculator(6)}`};
  margin-bottom: ${(p): string => p.hasBackground ? '0' : `${spacingCalculator(6)}`};
  align-items: start;
`;

const DescriptionContainer = styled.div<{ isRightImage: boolean; }>`
  max-width: 95vw;
  width: 100%;
  margin: 0 auto;
  grid-column: auto / span 12;
  justify-content: center;
  order: 0;

  @media ${mediaQueries.tablet} {
    grid-column: auto / span 6;
    order: 1;
    padding-left: ${(p): string => p.isRightImage ? '0' : spacingCalculator(6)};
    padding-right: ${(p): string => p.isRightImage ? spacingCalculator(6) : '0'};
  }
`;

const ImageContainer = styled.div<{ isRightImage: boolean; }>`
  grid-column: auto / span 12;
  order: 1;

  img {
    width: 100%;
    height: auto;
  }

  @media ${mediaQueries.tablet} {
    grid-column: auto / span 6;
    order: ${(p): number => p.isRightImage ? 2 : 0};
  }
`;

export default SideImage;
