import React, { FC } from 'react';
import styled from '@emotion/styled';

import {
  mediaQueries,
  spacingUpSide,
} from '@digando/react-component-library';
import Link from 'next/link';
import { CategoryCardItem } from './category-card-item';
import { CategoryTreeFragment } from '../../@types/codegen/graphql';
import { useTranslation } from 'next-i18next';
import { CategoryCardHeader } from './category-card-header';

export interface ICategoryCardProps {
  category: CategoryTreeFragment;
}
export const CategoryCard: FC<ICategoryCardProps> = ({ category,  }) => {
  const [t] = useTranslation(['common']);

  return (
    <CategoryCardWrapper>
      <CategoryCardHeader category={category} />

      <Category>
        {category?.children?.map((subcategories) => (
          (<Link
            key={subcategories?.id}
            href={subcategories?.relativeUrl ?? '/'}
            passHref
            prefetch={false}
          >
            <CategoryCardItem title={subcategories?.title ?? ''}/>
          </Link>)
        ))}

        {0 === category?.children?.length && (
          <Link
            key={category?.id}
            href={category?.relativeUrl ?? '/'}
            passHref
            prefetch={false}
          >
            <CategoryCardItem title={t('common:navigation.show-all-categories')}/>
          </Link>
        )}
      </Category>
    </CategoryCardWrapper>
  );
};

const CategoryCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: var(--category-card-border-radius);
  border: 1px solid var(--secondary-color);
  background-color: var(--white);

  @media ${mediaQueries.tablet} {
    border: none;
    min-height: 300px;
    width: 100%;
  }
`;

const Category = styled.div`
  width: 100%;
  ${spacingUpSide(3, 1)};
  transition: all 0.3s ease;
  font-size: var(--font-size-text);
  overflow: hidden;

  @media ${mediaQueries.tablet} {
    ${spacingUpSide(3, 1)};
    width: 100%;
    max-height: 100vh;
    opacity: 1;
    transition: all 0s;
  }

  a {
    color: var(--black);
  }
`;
