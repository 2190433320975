import { mediaQueries } from '@digando/react-component-library';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { ContentContainerMax } from '../../layout/container';
import { useTheme } from '@emotion/react';
import { useCategoriesContext } from '../../store/categories.context';
import { CategoryCard } from './category-card';

export const CategoryOverviewSection: FC = () => {
  const { firstLevelCategories } = useCategoriesContext();
  const theme = useTheme();

  const CategorySection = firstLevelCategories.map((category) => {
    return (
      <CategoryCardWrapper key={`category-card-${category?.id}`} contentType={theme.contentType}>
        <CategoryCard category={category}/>
      </CategoryCardWrapper>
    );
  });

  return <CategoryWrapper>{CategorySection}</CategoryWrapper>;
};

const CategoryCardWrapper = styled.div<{contentType: 'default' | 'content'}>`
  grid-column: auto / span 12;

  @media ${mediaQueries.tablet} {
    grid-column: auto / span 4;
  }

  @media ${mediaQueries.laptopL} {
    grid-column: auto / span 3;
  }

  @media ${mediaQueries.desktop} {
    grid-column: auto / span ${(p): string => 'content' === p.contentType ? '3' : '2'};
  }
`;

const CategoryWrapper = styled(ContentContainerMax)`
  @media ${mediaQueries.desktop} {
    grid-gap: 12px;
  }
`;
