import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import styled from '@emotion/styled';
import {
  FontSecondaryRegularItalic,
  FontPrimaryRegular,
  spacingTop,
  spacingTopBottom,
} from '@digando/react-component-library';
import {ComponentContentQuoteBlock} from '../../../@types/codegen/graphql'

type QuoteBlockProps = ComponentContentQuoteBlock & { sliceIndex: string; };

const QuoteBlock: FC<QuoteBlockProps> = ({ quote, author }) => (
  <ComponentWrapper>
    <TextWrapper>
      <p>{quote}</p>
    </TextWrapper>
    <AuthorWrapper>
      <p>{author}</p>
    </AuthorWrapper>
  </ComponentWrapper>
);

const ComponentWrapper = styled(ContentContainerMax)`
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0;
  ${spacingTopBottom(3)};
`;

const TextWrapper = styled.div`
  ${FontSecondaryRegularItalic};
  grid-column: 2 / span 10;
  grid-row: 1;

  p {
    color: var(--secondary-color);
    font-size: 30px;
    line-height: 36px;
  }
`;

const AuthorWrapper = styled.div`
  ${FontPrimaryRegular};
  ${spacingTop(0.5)};
  grid-column: 2 / span 10;
  font-size: var(--font-size-text);
  display: block;
`;

export default QuoteBlock;
