import React, { FC } from 'react';
import styles from './quote-card.module.scss';

export interface IQuoteCard {
  quote: string;
  author: string;
  company?: string;
  authorRole?: string;
}

export const QuoteCard: FC<IQuoteCard> = (props): JSX.Element => {
  const { quote, author, company, authorRole } = props;

  return (
    <div className={styles.quoteCardContainer}>
      <div className={styles.quote}>{quote}</div>
      <div>
        {author && <div className={styles.author}>{author}</div>}
        {company && <div className={styles.company}>{company}</div>}
        {authorRole && <div className={styles.role}>
          <p>{authorRole}</p>
        </div>}
      </div>
    </div>
  );
};
