import React, { FC } from 'react';
import styles from './category-card-item.module.scss';

interface ICategoryCardItem {
  title: string;
}

export const CategoryCardItem: FC<ICategoryCardItem> = ({ ...props }) => {
  return (
    <div className={styles.categoryCardItem}>
      <div className={styles.item}></div>
      <span className={styles.titleWrapper}>{props.title}</span>
    </div>
  );
};
